<div class="comment-control">
    <h2>Comments</h2>
    <div *ngIf="canAddComments" class="comment-form">
        <lib-metaform-display [form]="form" [showButtons]="false" [buttonErrorText]="'Disabled'"
            (formEvent)="onFormEvent($event)"></lib-metaform-display>
        <div class="comment-bar">
            <button [disabled]="!commentValid" type="button" class="add-comment" (click)="saveComment()"><i
                    class="fas fa-comment-medical"></i></button>
        </div>
    </div>
    <div class="comment-block">
        <div *ngIf="!isLoading">
            <div class="comment" *ngFor="let c of filteredComments">
                <p class="header">{{c.by}} <span class="date">- {{c.displayDate}}</span></p>
                <p class="text">{{c.comment}}</p>
            </div>
        </div>
    </div>
    <app-spinner *ngIf="isLoading"></app-spinner>
</div>