import { UserFormService } from '@aifs-shared/forms/user-form.service';
import { UserService } from '@aifs-shared/user/user.service';
import { MetaForm, MetaFormUserEvent, MFValueChange } from '@aifs-metaform/public-api';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { InterviewerService } from 'src/app/interviewer/interviewer.service';
import { CommentDetail, CommentDetailResponse } from './comments';
import { PortalUserPermission, PortalUserService } from '@aifs-shared/portal/portal-user-service';
import { InterviewerChangedItem, InterviewerDetailChanged } from 'src/app/interviewer/interviewer-detail-changed';

@Component({
    selector: 'app-int-comment-display',
    templateUrl: './interviewer-comments.component.html',
    styleUrls: ['./style/comments.component.scss']
})
export class InterviewerCommentsComponent implements OnInit, OnChanges, OnDestroy {

    @Input() interviewerId: number = 0;

    constructor(
        private userService: UserService,
        private interviewerService: InterviewerService,
        private portalService: PortalUserService,
        private userForm: UserFormService) {

        this.form = this.userForm.createCommentForm();
        this.form.change$
            .subscribe((chg: MFValueChange) => {
                this.commentValid = chg.value;
            });

    }

    ngOnInit(): void {

        this.changeSubscription = this.interviewerService.interviewerDetailsChanged$.subscribe({
            next: (changeItem: InterviewerDetailChanged) => {
                // console.log(`Changed!`);
                if (this.interviewerId === changeItem.interviewerId)
                    this.getComments(changeItem.interviewerId);
            }
        });
        this.canAddComments = this.portalService.hasPermission(PortalUserPermission.EditInterviewer);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['interviewerId'].currentValue !== changes['interviewerId'].previousValue) {
            if(this.interviewerId !== 0) this.getComments(changes['interviewerId'].currentValue);
        }
    }

    ngOnDestroy(): void {
        this.changeSubscription.unsubscribe();
        this.form.change$.unsubscribe();
    }

    onFormEvent(event: MetaFormUserEvent): void {
    }

    getComments(interviewerId: number) {
        this.commentValid = false;
        this.filteredComments = [];
        this.isLoading = true;

        this.interviewerService
            .getComments(interviewerId)
            .subscribe({
                next: (r: CommentDetailResponse) => {
                    if (r.comments) {
                        this.loadComments(r.comments);
                        this.isLoading = false;
                    }
                }, error: (error: any) => {
                    console.error(error);
                    this.isLoading = false;
                }
            });
    }

    saveComment() {
        const user = this.userService.user();
        if (user) {

            this.interviewerService
                .addComment(this.interviewerId, this.portalService.getUser().userId, this.form.getValue('comment'))
                .subscribe({
                    next: (r: CommentDetailResponse) => {
                        this.form.setValue('comment', '');
                        if (r.comments) {
                            this.loadComments(r.comments);
                        }
                    },
                    error: (error: any) => {
                        console.error(error);
                    }
                });
        }
    }

    loadComments(r: CommentDetail[]): void {
        this.filteredComments = r.sort((c1, c2) => c1.id < c2.id ? 1 : -1);
        this.hasComments = this.filteredComments.length > 0;
    }

    form!: MetaForm;
    hasComments = false;
    filteredComments: CommentDetail[] = [];
    commentValid = false;
    changeSubscription!: Subscription;
    canAddComments = false;

    isLoading = false;
}
