import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommentsComponent } from './comments.component';
import { CommentsIssueComponent } from './comments-issue.component';
import { SpinnerModule } from '@aifs-shared/spinner/spinner.module';
import { InterviewerCommentsComponent } from './interviewer-comments.component';
import { MetaFormModule } from '@aifs-shared/ngx-metaform/metaform.module';

@NgModule({
    declarations: [
        CommentsComponent,
        CommentsIssueComponent,
        InterviewerCommentsComponent
    ],
    imports: [
        CommonModule,
        MetaFormModule,
        SpinnerModule
    ],
    exports: [
        CommentsComponent,
        CommentsIssueComponent,
        InterviewerCommentsComponent
    ]
})
export class CommentModule { }
